import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "../src/reducer/index";
import { GoogleOAuthProvider } from "@react-oauth/google";
const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID;

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const checkForNewVersionCp = async () => {

  const currentVersionCp = localStorage.getItem('buildVersionCp_OBS');

  try {

    const response = await fetch('/version.json', { cache: 'no-store' });
    const { buildVersionCp_OBS } = await response.json();

    if (currentVersionCp) {
      if (currentVersionCp !== buildVersionCp_OBS) {
        console.log(`New version available: ${buildVersionCp_OBS}. Current version: ${currentVersionCp}.`);

        localStorage.setItem('buildVersionCp_OBS', buildVersionCp_OBS);
        if ('caches' in window) {
          caches.keys().then((names) => {
            names.forEach((name) => caches.delete(name));
          });
        }
        window.location.reload(true);
      } else {
        console.log(`App is up-to-date. Current version: ${buildVersionCp_OBS}.`);
      }
    } else {
      console.log(`Setting initial version: ${buildVersionCp_OBS}`);
      localStorage.setItem('buildVersionCp_OBS', buildVersionCp_OBS);
    }
  } catch (error) {
    console.error("Error checking app version:", error);
  }
};

checkForNewVersionCp();

ReactDOM.render(
  <GoogleOAuthProvider clientId={clientId}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
